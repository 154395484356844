import React, { Component, useState, useEffect } from "react";
import { navigate } from "gatsby";
import { handleLogin, isLoggedIn } from "../services/auth";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useMutation} from "@apollo/client";
import { LOGIN_MUTATION } from "../graphql/auth";

function Admin() {
  // navigate(`https://foodyadmin.hurkanyakay.com/admin`);
  useEffect(() => {
    if (window) {
      window.location.replace(`https://foodyadmin.hurkanyakay.com/admin`);
    }
  }, [])
  
  return (
    <Container maxWidth="xs">
      
    </Container>
  );
}

export default Admin;
